import { createContext, ReactNode, useEffect, useReducer } from 'react';
import useSettings from 'hooks/useSettings';
import { xxTest, apiClient } from '_apis_/api';
// utils
import Cookies from 'js-cookie';
import axios from '../utils/axios';
import { isValidToken, setSession, verify } from '../utils/jwt';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/authentication';

import { SERVER } from '../config';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Logout = 'LOGOUT',
  Register = 'REGISTER'
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };

    case 'REGISTER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const { changeMode } = useSettings();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const secret = process.env.REACT_APP_JWT_SECRET;
          let dumm = '';
          if (secret !== undefined) {
            dumm = secret;
          }
          const data: any = verify(
            accessToken,
            '9wgPadLRRPN5qqQvvfKhWqpxigluoGye5IERhnzQvwepC_3j8eAUqMiSphijAsxAcz8v7cgQYvYfbC7aepe'
          );
          const userId = typeof data === 'object' ? data?.id : '';
          const response = await xxTest().get(`/kunde/${userId}`);

          const user = response.data[0];
          setTheme(user?.standort);

          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (kundeid: string, password: string) => {
    const response = await apiClient.post(`/auth/login`, { kid: kundeid, password });
    const res = response.data;

    const accessToken = res.tokens.access.token;
    const user = res.kunde[0];
    await setSession(accessToken);
    await setTheme(user?.standort);
    dispatch({
      type: Types.Login,
      payload: {
        user
      }
    });
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: Types.Register,
      payload: {
        user
      }
    });
  };

  const logout = async () => {
    setSession(null);
    window.localStorage.clear();
    dispatch({ type: Types.Logout });
  };

  const resetPassword = (email: string) => console.log(email);

  const setTheme = (standort: string) => {
    if (standort == 'MO') {
      changeMode('dark', 'default');
      // onChangeColo('default');
    } else {
      changeMode('light', 'cyan');
      // onChangeColo('cyan');
    }
  };

  const updateProfile = () => {};

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
