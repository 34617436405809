// hooks
import useAuth from '../hooks/useAuth';
//
import { MAvatar } from './@material-extend';
import { MAvatarProps } from './@material-extend/MAvatar';
import createAvatar from '../utils/createAvatar';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }: MAvatarProps) {
  const { user } = useAuth();
  const faviconUrl = user?.website
    ? `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=https://${user.website}&size=64`
    : '';

  return (
    <MAvatar
      src={faviconUrl || undefined} // Falls keine Website vorhanden ist, bleibt `src` leer
      alt={user?.firma || 'Avatar'}
      color={faviconUrl ? 'default' : createAvatar(user?.displayName).color}
      {...other}
    >
      {!faviconUrl && createAvatar(user?.firma).name} {/* Falls kein Logo, zeige Initialen */}
    </MAvatar>
  );
}
